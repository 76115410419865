.container {
  position: fixed;
  height: auto;
  width: 300px;
  max-width: calc(100vw - 30px);
  z-index: 99999;
  right: 15px;
  pointer-events: none;
  bottom: 15px;

  @media (min-width: $mantine-breakpoint-m) {
    top: 80px;
    bottom: auto;
  }
}

.toast {
  cursor: auto;
  background: var(--grid-column-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--quo-char-50) 0px 1px 4px -1px;
  width: 100%;
  min-height: 50px;
  text-align: left;
  display: flex;
  align-items: stretch;
  pointer-events: all;
  will-change: transform, opacity;
  animation: enter 0.2s forwards;
  position: relative;
  padding-right: 5px;

  &.success {
    --toast-color: var(--message-success);
  }

  &.warning {
    --toast-color: var(--message-warning);
  }

  &.error {
    --toast-color: var(--message-error);
  }

  @keyframes enter {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }

    100% {
      opacity: 1;
      top: translateX(0);
    }
  }

  &:not(:first-child) {
    margin-top: 15px;
  }
}

.label {
  font-weight: bold;
  color: var(--toast-color);
  display: block;
}

.message {
  margin-top: 5px;
  display: block;
}

.link {
  a {
    display: block;
    margin-top: 5px;
    word-break: break-all;
  }
}

.icon {
  position: relative;
  padding-left: 16px;

  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 100%;
    background-color: var(--toast-color);
    position: absolute;
    left: 0;
  }
}

.output {
  padding: 10px;
  flex-grow: 1;
}

.closeIcon {
  align-self: center;
  cursor: pointer;
  flex-shrink: 0;
}
