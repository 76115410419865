.list {
  :global(li:not(:first-child)) {
    margin-top: var(--mantine-spacing-xs);
  }

  &.row {
    :global(li:not(:first-child)) {
      margin-top: 0;
    }
  }
}
