.mobileWrapper {
  position: absolute;
  width: 100%;
  left: 0;
  min-height: 65px;
  padding: 15px;
  background: var(--mercury-bg);
  border-bottom: 2px solid var(--quo-grey-15);
  border-radius: var(--border-radius);
}
