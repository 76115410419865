[data-mantine-color-scheme='dark']:root {
  /* Primary */
  --quo-grey-15: var(--quo-char);

  /* Content */
  --mercury-bg: #1a1c20;
  --svg-icon-color: var(--mantine-color-text);
  --grid-column-bg: var(--quo-char);
  --grid-column-shadow: var(--quo-char-90);
  --rte-toolbar-button-color: var(--quo-grey-75);
  --rte-toolbar-button-active-color: var(--quo-grey-25);
  --modal-bg: var(--quo-char-90);
  --link-color: var(--quo-power-50);
  --link-color-hover: var(--quo-power);
  --title-color: var(--quo-blue-25);
  --item-bg: var(--quo-char-75);
  --item-bg-hover: var(--quo-char-50);
  --icon-column-stroke: var(--quo-blue-50);
  --icon-column-location-stroke: var(--quo-blue-50);
  --icon-column-rect-stroke: var(--quo-blue-50);
  --icon-column-fill: none;
  --menu-item-highlight: var(--quo-grey-50);
  --menu-item-selected: var(--quo-char-50);
  --default-border: var(--quo-grey-75);
  --placeholder-bg: var(--grid-column-bg);
  --drawer-border-color: var(--quo-char);
  --error-message-bg: var(--quo-power-50);
  --error-message-font: var(--quo-char);
  --success-message-bg: var(--quo-blue-75);
  --success-message-font: var(--quo-white);
  --warning-message-font: var(--quo-char);

  /* Date Picker States */
  --datePicker-hover: var(--quo-blue-75);
  --datePicker-selected: var(--quo-blue-75);
  --datePicker-selectedStartEnd: var(--quo-blue);
  --datePicker-selectionMiddleHover: var(--quo-blue);
  --datePicker-presetHover: var(--quo-blue);

  /* Nav */
  --sidebar-flyout-active: var(--white-alpha-300);
  --dropdown-menu-bg: var(--quo-char-90);
  --dropdown-item-border: var(--quo-noir-50);
  --dropdown-item-hover-bg: var(--white-alpha-300);
  --notification-badge-color: var(--quo-power-75);
  --notification-unread-color: var(--quo-power-75);
}
