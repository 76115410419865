.barNavList {
  margin-left: auto;

  :global(svg) {
    width: var(--icon-size);
    height: var(--icon-size);
    transition: transform 100ms ease-in, width 100ms ease-in;
  }
}

.iconWrapper {
  width: 100%;
  height: var(--icon-size);

  &.expanded {
    :global(> svg:last-child) {
      transform: rotate(180deg);
    }
  }
}

.dropdown {
  position: relative;
  --dropdown-menu-size: 180px;
}

.dropdownMenu {
  width: var(--dropdown-menu-size);
  position: absolute;
  display: none;
  right: 0;
  margin-top: 10px;
  background: var(--dropdown-menu-bg);
  border: 1px solid var(--white-alpha-300);
  border-radius: var(--border-radius);
  box-shadow: var(--grid-column-shadow) 0px 0 3px 0px;

  &:before {
    content: '';
    position: absolute;
    right: 7.25px;
    margin-left: 4px;
    top: -5px;
    transform: translateY(-50%);
    border: 5px solid #000;
    border-color: transparent transparent var(--dropdown-menu-bg) transparent;
  }

  &.expanded {
    display: block;
  }
}

.menuItem {
  cursor: pointer;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  a,
  button {
    display: block;
    width: 100%;
    padding: 10px 8px;
    text-align: left;
    height: 45px;
  }

  &:hover a,
  &:hover button {
    background-color: var(--dropdown-item-hover-bg);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--dropdown-item-border);
  }
}

.coinValue {
  font-weight: bold;

  &:not(:last-child) {
    margin-right: 15px;
  }

  &.low {
    color: var(--quo-power-75)
  }
}

.coinIcon {
  width: var(--icon-size);
  height: var(--icon-size);
  margin-right: 5px;
}

.barWrapper {
  display: flex;
  gap: 15px;

  @media (min-width: $mantine-breakpoint-s) {
    margin-left: auto;
  }

  @media (min-width: $mantine-breakpoint-m) {
    margin-left: 0;
    width: 100%;

    > :first-child {
      flex: 1;
    }
  }

  &.noSearch {
    width: auto;
    margin-left: auto;
  }
}

.disclaimer {
  width: 100%;
  background-color: var(--quo-yellow);
  color: var(--quo-char);
  text-align: center;
  padding: 5px;
  font-weight: bold;
  white-space: nowrap;
}
