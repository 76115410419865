.preview {
  border: 1px solid var(--preview-border);
  border-radius: var(--border-radius);
  margin-top: 25px;
  padding: 15px;
  font-size: 14px;

  @mixin light {
    --preview-border: var(--mantine-color-gray-4);
  }

  @mixin dark {
    --preview-border: var(--mantine-color-dark-4);
  }
}
