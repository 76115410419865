.root {
  img {
    max-width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: contain;
  }

  pre {
    border-left: 3px solid var(--quo-power);
    page-break-inside: avoid;
    max-width: 100%;
    white-space: pre-wrap;

    code {
      background-color: transparent;
      font-size: inherit;
      color: inherit;
      word-break: normal;
      padding: 0;
    }
  }

  code {
    font-family: Courier, monospace;
    padding: 0.1rem 0.4rem;
    border-radius: 0.1335rem;

    + code {
      margin-left: 5px;
    }
  }

  sup {
    a {
      font-size: inherit;
      line-height: inherit;
      text-decoration: none;
    }
  }

  ul {
    list-style: initial;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    padding-left: 15px;
    margin-left: 15px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0px;
    width: 100%;
    table-layout: fixed;
    overflow-wrap: anywhere;

    th {
      width: 200px;
      text-align: left;
    }

    tr {
      vertical-align: middle;

      &:first-child {
        td:first-child {
          border-left-style: solid;
          border-top-left-radius: 4px;
        }

        td:last-child {
          border-right-style: solid;
          border-top-right-radius: 4px;
        }
      }

      &:last-child {
        td {
          border-bottom: 1px solid var(--default-border);

          &:first-child {
            border-left-style: solid;
            border-bottom-left-radius: 4px;
          }

          &:last-child {
            border-right-style: solid;
            border-bottom-right-radius: 4px;
          }
        }
      }



      thead & {
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-top: 1px solid transparent;
      }

      th {
        text-transform: capitalize;
      }

      td {
        border-top: 1px var(--quo-grey-25);
        border-style: solid none;
        border-bottom: 1px;

        &:first-child {
          border-left: 1px solid var(--default-border)
        }

        &:last-child {
          border-right: 1px solid var(--default-border)
        }

        tbody & {
          overflow-wrap: break-word;
          word-break: break-word;
        }
      }

      th,
      td {
        padding: 10px;

        @media (min-width: ${(props) => props.theme.breakpoints.M}) {
          padding: 12px;
        }
      }

      th,
      td {
        font-size: 14px;
        line-height: 24px;
        text-align: left;
      }
    }
  }

  :global(.footnotes) {
    font-size: smaller;
    color: var(--quo-grey-75);
    border-top: 1px solid var(--quo-grey-15);
    padding-top: 15px;

    li {
      scroll-margin-top: 75px;
    }
  }

  /* Hide the section label for visual users. */
  :global(.sr-only) {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    word-wrap: normal;
    border: 0;
  }

  [data-footnote-ref]::before {
    content: '[';
  }

  [data-footnote-ref]::after {
    content: ']';
  }
}
