.root {
  svg {
    fill: var(--ai-color);
  }

  &[data-variant='outline'] {
    border: 1px solid var(--default-border)
  }

 &[data-disabled=true] {
  background-color: transparent;

  svg {
    opacity: 0.5;
  }
 }
}
