.inner {
  align-items: center;
  justify-content: center;

  @media (min-width: $mantine-breakpoint-s) {
    justify-content: end;
  }
}

.content {
  height: auto;
  max-height: calc(100vh - 30px);
  max-width: calc(100% - 30px);
  border: 1px solid var(--drawer-border-color);

  @media (min-width: $mantine-breakpoint-s) {
    max-height: calc(100% - 30px);
    max-width: 450px;
  }
}

.title {
  font-size: 24px;
  word-break: break-word;
}

.headerWithLink {
  flex-wrap: wrap-reverse;
  justify-content: end;
  gap: 15px;
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
