.root {
  align-items: flex-start;
  --tab-border-width: 0 0 3px 0;

  &[data-orientation='vertical'] {
    --tab-border-width: 0 3px 0 0;
  }
}

.list {
  &.sticky {
    position: sticky;
    top: 75px;
  }
}

.tab {
  --tab-border-color: transparent;
  --tab-color: light-dark(var(--quo-blue), var(--quo-blue-50));
  font-size: 18px;
  cursor: pointer;
  padding: 25px;
  min-height: 30px;
  border-width: var(--tab-border-width);
  border-style: solid;
  border-color: var(--tab-border-color);
  font-weight: bold;

  &.small {
    padding: 10px;
    font-size: 14px;
  }

  &[data-active] {
    --tab-border-color: var(--quo-blue-50);
    color: var(--tab-color);
  }

  &:hover {
    color: var(--tab-color);
    background: none;
  }

  &[data-disabled] {
    cursor: not-allowed;
  }
}

.panel {
  &[data-orientation='vertical'] {
    margin-left: 25px;
  }

  &[data-orientation='horizontal'] {
    margin-top: 25px;
  }
}

.selectSection {
  &[data-position='right'] {
    padding-right: 12px;
  }
}
