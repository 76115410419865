.root {
  font-weight: normal;
  min-width: 100%;

  @media (min-width: $mantine-breakpoint-sm) {
    min-width: 100px;
  }

  &:disabled,
  &[data-disabled] {
    background-color: var(--button-bg);

    svg {
      opacity: 0.5;
    }
  }

  svg {
    fill: var(--button-color);
  }

  &[data-variant='outline'] {
    color: var(--mantine-color-text);
    border: 1px solid var(--quo-blue-75);

    &:disabled,
    &[data-disabled] {
      opacity: 0.5;
    }
  }

  &[data-variant='subtle'] {
    --button-color: var(--mantine-color-text) !important;
  }
}
