.link {
  display: inline-block;
  position: relative;

  &:hover {
    :global(svg) {
      fill: var(--quo-power);
    }
  }
}

.regular {
  color: var(--link-color);
  text-decoration: underline;
  text-underline-offset: 4px;
  line-height: 22px;
  text-align: inherit;

  &:hover {
    color: var(--link-color-hover);
  }

  &:disabled {
    color: var(--quo-blue-25);
    cursor: not-allowed;
    font-weight: normal;
  }
}

.external {
  margin-left: 5px;

  svg {
    fill: var(--link-color);

    &:hover {
      fill: var(--link-color-hover);
    }
  }
}

.highlight {
  font-weight: bold;
}

.animate {
  animation: animate 2s infinite linear;

  @keyframes animate {
    0% { opacity: 1 }
    50% { opacity: 0.4 }
    100% { opacity: 1 }
  }
}
