@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../assets/fonts/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../assets/fonts/Inter-Bold.woff2') format('woff2');
}
