.dropdown {
  padding: 0;
}

.option {
  padding-right: 0 !important;
  border-radius: 0;
  flex: 1;

  &[data-combobox-active]:not(.parent) {
    background: var(--menu-item-selected);
    color: var(--quo-grey);
  }

  &.parent {
    padding-left: 0;

    &:hover {
      background: none;
    }
  }

  &.highlightLabel,
  &.parent {
    font-weight: bold;
  }
}

.rootCheckbox {
  padding-left: 15px;
}

.selected {
  border: 1px solid var(--default-border);
  border-radius: var(--border-radius);
  font-size: 13px;
  padding: 5px;

  :global(button) {
    color: var(--quo-power);
  }
}
