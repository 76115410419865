.pill {
  background-color: var(--pill-bg);
  color: var(--pill-font-color);
  border-radius: 4px;
  padding: 3px 5px;
  font-size: 14px;
  margin-top: 0 !important;
  display: inline-block;

  &.big {
    padding: 5px 10px;
  }

  &.highlight {
    animation: highlight 3s infinite linear;

    @keyframes highlight {
      0% { background-color: var(--pill-bg) }
      50% { background-color: var(--pill-highlight) }
      100% { background-color: var(--pill-bg) }
    }
  }

  &.full {
    width: 100%;
    text-align: center;
  }
}
