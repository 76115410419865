.form {
  width: 100%;

  &:not(:first-child) {
    margin-top: 15px;
  }

  > *:not(:first-child),
  fieldset > *:not(:first-child) {
    margin-top: 25px;
  }

  fieldset {
    padding: 0;

    > legend {
      font-weight: bold;
      width: 100%;

      + *:not(:first-child) {
        margin-top: 10px;
      }
    }
  }

  &.small {
    width: 50%;
  }
}

.buttonGroup {
  :global(> button:first-child) {
    border: 1px solid var(--quo-blue-75);
  }

  :global(> button:last-child) {
    flex: 1;
  }
}

.file {
  font-size: 12px;
}

.clearFiles {
  color: var(--active-color);
  font-weight: bold;
  flex-shrink: 0;
}

.input {
  &:read-only {
    background-color: var(--item-bg);
    opacity: 0.7;
  }
}

.inputWrapper {
  position: relative;
}

.labelLink {
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
}
