.linkLabel {
  display: block;
  white-space: nowrap;
  margin-left: 15px;
}

.expandWrapper {
  margin-top: 10px;
  padding-top: 10px;

  &:before {
    content: "";
    position: absolute;
    border-top: 1px solid var(--quo-grey-15);
    left: 0;
    bottom: 50px;
    width: 100%;
  }
}
.utilityIcon {
  display: block;
  width: 25px;
  height: 25px;
  padding-left: 10px;

  :global(svg) {
    transition: transform ease 0.03s;
  }

  &.expanded {
    :global(svg) {
      transform: rotate(180deg);
    }
  }
}

.toggleLabel {
  display: block;
  white-space: nowrap;
  transition: opacity ease 0.05s;

  @media (min-width: $mantine-breakpoint-sm) {
    opacity: 0;
  }

  &.expanded {
    opacity: 1;
  }
}

.svgColors {

  :global(svg.fill),
  :global(svg.fill path) {
    fill: var(--svg-icon-color);
  }

  :global(svg.stroke),
  :global(svg.stroke path) {
    stroke: var(--svg-icon-color);
    fill: none;
  }
}

.svgFill {

  :global(svg.fill),
  :global(svg.fill path) {
    fill: var(--quo-power);
  }

}

.svgStroke {

  :global(svg.stroke),
  :global(svg.stroke path) {
    stroke: var(--quo-power);
  }
}

.link {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-left: 2px solid transparent;
  overflow: hidden;

  &:hover {
    color: var(--active-color);
  }

  li:not(:first-child) & {
    margin-top: 10px;
  }

  &.submenu {
    display: none;

    @media (min-width: $mantine-breakpoint-m) {
      display: flex;
    }
  }
}

.active {
  composes: svgFill;
  composes: svgStroke;
  border-left: 2px solid var(--quo-power);
  color: var(--active-color);
  font-weight: bold;
}

.linkIcon {
  composes: svgColors;
  display: block;
  width: var(--icon-size);
  height: var(--icon-size);

  :global(svg) {
    width: var(--icon-size);
    height: var(--icon-size);
  }

  a:hover & {

    :global(svg.fill),
    :global(svg.fill path) {
      fill: var(--quo-power);
    }

    :global(svg.stroke),
    :global(svg.stroke path) {
      stroke: var(--quo-power);
    }
  }
}

.submenuListWrapper {
  margin-top: 10px;
}

.submenuList {
  @media (min-width: $mantine-breakpoint-m) {
    padding: 10px 5px;
    background: var(--dropdown-menu-bg);
    border: 1px solid var(--white-alpha-300);
    border-radius: var(--border-radius);
    box-shadow: var(--grid-column-shadow) 0px 0 3px 0px;
  }

  :global(a) {
    margin-left: 0;
    margin-right: 0;

    &.active {
      border: none;
      background-color: var(--sidebar-flyout-active);
      color: var(--mantine-color-text);
      border-radius: 4px;
    }
  }
}

.submenuItem {
  position: relative;
}

.submenuDropdown {
  background-color: transparent;
  padding: 0;
}
