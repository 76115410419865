.root {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 15px;

  & & {
    padding: 0;
  }

  &.removePadding {
    padding: 0;
  }
}

.col {
  background-color: var(--grid-column-bg);
  border-radius: var(--border-radius);
  width: 100%;
  padding: 15px;
  height: 100%;

  &:empty {
    display: none;
  }

  @media (min-width: $mantine-breakpoint-m) {
    padding: 25px;
  }

  &.transparent {
    background-color: transparent;
  }

  &.fullWidth,
  &.transparent {
    padding: 0;
  }
}
