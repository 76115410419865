.tableWrapper {
  &:not(:first-child) {
    margin-top: 25px;
  }
}

.tableMain {
  position: relative;
}

.table {
  border-collapse: separate;
  border-spacing: 0px;
  width: 100%;
  table-layout: fixed;

  &:not(:first-child) {
    margin: 14px auto 0;
  }
}

.tableOverflow {
  overflow-x: auto;

  &:not(:first-child) {
    margin-top: 15px;
  }
}

.thContent {
  display: inline-flex;
  flex-direction: inherit;
  align-items: center;
  vertical-align: middle;

  svg:not(:global(.mantine-Checkbox-icon), :global(.columnInfo)) {
    width: 20px;
    height: 20px;
    fill: var(--quo-grey-75);
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
  }

  &.contentDesc svg {
    transform: rotate(180deg);
  }

  &.sortable {
    cursor: pointer;
  }

  &.contentSorted,
  &:hover {
    svg {
      opacity: 1;
    }
  }
}

.tableElement {
  &.numeric {
    flex-direction: row-reverse;
    text-align: right;
  }

  &.center {
    text-align: center;
  }
}

.tr {
  vertical-align: middle;

  &:first-child {
    td:first-child {
      border-left-style: solid;
      border-top-left-radius: 4px;
    }

    td:last-child {
      border-right-style: solid;
      border-top-right-radius: 4px;
    }
  }

  &:last-child {
    td {
      border-bottom: 1px solid var(--default-border);

      &:first-child {
        border-left-style: solid;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-right-style: solid;
        border-bottom-right-radius: 4px;
      }
    }
  }

  thead & {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-top: 1px solid transparent;
  }

  tbody & {
    &.highlighted td {
      font-weight: bold;

      &:first-child {
        border-left: 3px solid var(--quo-power);
      }
    }
  }

  th {
    font-weight: bold;
    text-transform: capitalize;
  }

  td {
    font-size: 14px;
    border-top: 1px var(--quo-grey-25);
    border-style: solid none;
    border-bottom: 1px;

    &:first-child {
      border-left: 1px solid var(--default-border);
    }

    &:last-child {
      border-right: 1px solid var(--default-border);
    }

    tbody & {
      overflow-wrap: break-word;
      word-break: break-word;
    }
  }

  th,
  td {
    text-align: left;
    padding: 10px;

    @media (min-width: $mantine-breakpoint-m) {
      padding: 12px;
    }
  }
}

.messageTd {
  padding: 25px;
  text-align: center;
  font-size: 25px;
  color: var(--quo-grey-50);
}

.selectionWrapper {
  &.enter {
    opacity: 0;
    transform: translateY(100%);
  }

  &.enterActive {
    opacity: 1;
    transform: translateY(0%);
  }

  &.exit {
    opacity: 1;
    transform: translateY(0%);
  }

  &.exitActive {
    opacity: 0;
    transform: translateY(100%);
  }

  &.enterActive,
  &.exitActive {
    transition: opacity 250ms, transform 250ms;
  }
}

.autoLayout {
  table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
  }
}
