.wrapper {
  display: inline-flex;
  align-items: center;
  grid-gap: 5px;
  padding: 3px 10px;
  color: var(--icon-column-stroke);
  border: 1px solid var(--icon-column-stroke);
  border-radius: var(--border-radius);
  font-weight: bold;
  cursor: default;

  path {
    fill: var(--icon-column-stroke);
  }

  svg:first-child {
    flex-shrink: 0;
  }

  &.small {
    padding: 3px 5px;
    font-size: 12px;
  }
}
