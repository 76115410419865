.title {
  span {
    display: block;

    & + span {
      margin-top: 5px;
    }
  }

  b {
    font-weight: bold;
    color: var(--quo-power-75);
  }
}
