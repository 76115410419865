.metaTitle {
  display: block;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;  /* This is crucial for text truncation to work in flex containers */
}

.metaRoot {
  &:not(:first-child) {
    margin-top: 25px;
  }
}

.container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 278px), 1fr));
}

.section {
  &:not(:first-child) {
    margin-top: 25px;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clear {
  font-size: 14px;
  flex-shrink: 0;

  &.all {
    color: var(--quo-power);
  }
}

.metaComponentWrapper {
  margin-top: 5px;
}

.searchIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  bottom: 7px;
}

.search {
  width: 100%;

  @media (max-width: $mantine-breakpoint-mm) {
    flex: 1;
  }

  @media (min-width: $mantine-breakpoint-m) {
    width: 400px;
  }
}
