@media print {
  .noPrint {
    display: none;
  }

  /* Defining all page breaks */

  ul,
  ol,
  dl {
    break-inside: avoid;
  }

  header,
  #sidebar {
    display: none !important;
  }

  /* Displaying link color and link behaviour */
  a:link,
  a:visited,
  a,
  button {
    background: transparent;
    color: var(--font-color) !important;
    text-decoration: none !important;
    text-align: left;
  }

  a[href^='http']:after {
    content: ' (' attr(href) ') ';
    text-decoration: underline;
  }

  a:after > img {
    content: '';
  }

  article a[href^='#']:after {
    content: '';
  }

  a:not(:local-link):after {
    content: ' (' attr(href) ') ';
    text-decoration: underline;
  }
}
