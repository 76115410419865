.paginationControl {
  border: 1px solid var(--default-border);

  &:not(:disabled):hover {
    background-color: var(--item-bg-hover);
  }

  &[data-active] {
    color: var(--quo-power);
    border: 1px solid var(--quo-power);
    background: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.paginationButton {
  &:disabled svg {
    fill: var(--quo-grey-25);
  }

  svg {
    width: 25px;
    height: 25px;
  }
}

.entries {
  border: none;
}
