.grid {
  /**
  * User input values.
  */
  --grid-layout-gap: 15px;
  --grid-column-count: 1;
  --grid-item--min-width: 200px;

  /**
  * Calculated values.
  */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);
  padding: 0;

  @media (min-width: $mantine-breakpoint-s) {
    --grid-column-count: var(--grid-column-count-S);
  }

  @media (min-width: $mantine-breakpoint-m) {
    --grid-column-count: var(--grid-column-count-M);
  }

  @media (min-width: $mantine-breakpoint-l) {
    --grid-column-count: var(--grid-column-count-L);
  }
}
