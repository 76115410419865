/* DatePicker.module.css */

.datePickerMonth {
  margin-left: 15px;
}

.datePickerCalendarContainer {
  @media (max-width: $mantine-breakpoint-xs) {
    flex-direction: column;
  }
}

.datePickerDropdown {
  background: none;
  border: none;
  padding: 0;
}

.datePickerCustom {
  background-color: var(--grid-column-bg);
  border: 1px solid var(--grid-column-bg);
  box-shadow: var(--quo-char-50) 0px 1px 4px -1px;
  display: flex;
  flex-direction: column;

  &.stretch {
    align-self: stretch;
    padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
  }
}

.datePickerCell {
  width: 25px;
  height: 25px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;

  &.hovered:hover {
    background-color: var(--datePicker-hover);
  }

  &.selected {
    background-color: var(--datePicker-selected);
    color: white;
  }

  &.selectionStartEnd {
    background-color: var(--datePicker-selectedStartEnd);
  }

  &.selectionMiddle:hover {
    background-color: var(--datePicker-selectionMiddleHover);
  }

  &.disabled {
    opacity: 0.5;
  }

  &.highlight {
    font-weight: bold;
  }
}

.datePickerCalendarGrid {
  margin-top: 10px;
  flex-grow: 1;
}

.datePickerGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 15px;
}

.datePickerWeeks {
  display: grid;
  grid-auto-rows: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.datePickerPreset {
  padding: 15px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--datePicker-presetHover);
    color: var(--quo-white);
  }
}

.datePickerSelect {
  background-color: transparent;
  color: inherit;
}

.datePickerError {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;

  :global(svg) {
    width: 15px;
  }
}
