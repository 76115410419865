.root {
  @media (min-width: $mantine-breakpoint-s) {
    --app-shell-navbar-offset: var(--app-shell-navbar-width) !important;
  }
}

.header {
  background-color: var(--mercury-bg);

  &.scrolled {
    box-shadow: rgba(25, 42, 70, 0.13) 0px 7px 6px 0px;
  }
}

.navbar {
  background-color: var(--mercury-bg);
  border-right: 1px solid var(--quo-grey-15);
  max-width: var(--app-shell-navbar-width);

  @media (max-width: $mantine-breakpoint-sm) {
    --app-shell-navbar-width: 265px;
  }

  @media (min-width: $mantine-breakpoint-s) {
    --app-shell-navbar-transform: 0;

    transition: max-width 300ms cubic-bezier(0.44, 0.8, 0.71, 0.98);
    width: auto;
  }
}

.logoSection {
  overflow: hidden;
}

.logoWrapper {
  display: table;
  width: 100%;
}

.logoComplete {
  height: 25px;
  margin-top: 15px;
  padding-left: 10px;

  @media (min-width: $mantine-breakpoint-s) {
    margin-top: 2.5px;
  }
}

.pageTitle {
  padding: 10px 15px;
}
