.title {
  font-size: 24px;
  word-break: break-word;
}

.alternativeBg {
  background-color: var(--mercury-bg);
}

.allowOverflow {
  overflow: visible;
}

.close {
  &:first-child {
    margin-left: auto;
  }
}

.headerWithLink {
  flex-wrap: wrap-reverse;
  justify-content: end;
  gap: 15px;
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  :first-child {
    flex: 1;
  }
}
