.wrapper {
  background: var(--wrapper-message-bg);
  padding: 15px;
  border-radius: 4px;

  &:not(:first-child) {
    margin-top: 25px;
  }
}

.text {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    color: var(--message-font-color);
  }

  button {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  }
}
