.wrapper {
  height: 100vh;
  padding: 20px;

  @media (min-width: $mantine-breakpoint-m) {
    padding: 40px;
  }
}

.text {
  padding: 20px;
  width: 100%;
  text-align: center;

  p {
    font-size: 22px;
  }

  button {
    width: 100%;
    margin-top: 25px;
    max-width: 250px;
  }

  @media (min-width: $mantine-breakpoint-m) {
    width: 50%;
    padding: 40px;
  }
}
