@import './normalize.css';
@import './print.css';
@import './darkTheme.css';

:root {
  /* Primary colors */
  --quo-tan: #b7aba0;
  --quo-power: #d65b32;
  --quo-blue: #2d5c66;
  --quo-noir: #445062;
  --quo-grey: #606060;
  --quo-char: #333333;
  --quo-white: #ffffff;
  --quo-pink: #ff5b5c;
  --quo-green: #6e956e;
  --quo-yellow: #e5ad4a;

  /* Color Variations - tan */
  --quo-tan-75: #c9c0b8;
  --quo-tan-50: #dbd5cf;
  --quo-tan-25: #edeae7;

  /* Color Variations - Power */
  --quo-power-75: #e08465;
  --quo-power-50: #eaad98;
  --quo-power-25: #f5d6cc;

  /* Color Variations - Blue */
  --quo-blue-90: #0c282f;
  --quo-blue-80: #4e6b70;
  --quo-blue-75: #61858c;
  --quo-blue-50: #96adb2;
  --quo-blue-25: #cad6d9;

  /* Color Variations - Noir */
  --quo-noir-75: #445062;
  --quo-noir-50: #a1a7b0;
  --quo-noir-25: #d0d3d8;

  /* Color Variations - Grey */
  --quo-grey-75: #888888;
  --quo-grey-50: #aeaeae;
  --quo-grey-25: #d7d7d7;
  --quo-grey-15: #dfe3e7;

  /* Color Variations - Black */
  --quo-char-90: #6a6565;
  --quo-char-75: #666666;
  --quo-char-50: #999999;
  --quo-char-25: #cccccc;

  /* Color Variations - Green */
  --quo-green-50: #00a958;
  --quo-green-25: #cce5cc;

  /* Alpha */
  --white-alpha-300: rgba(255, 255, 255, 0.16);

  /* Content */
  --mercury-bg: #f2f4f4;
  --grid-column-bg: var(--quo-white);
  --grid-column-shadow: var(--quo-char-25);
  --rte-toolbar-button-color: var(--quo-grey-75);
  --rte-toolbar-button-active-color: var(--quo-char);
  --modal-bg: var(--quo-white);
  --link-color: var(--quo-blue);
  --link-color-hover: var(--quo-blue-90);
  --border-radius: 4px;
  --title-color: var(--quo-blue);
  --item-bg: var(--quo-grey-15);
  --item-bg-hover: var(--quo-grey-25);
  --icon-column-stroke: #475e7b;
  --icon-column-location-stroke: #475e7b;
  --icon-column-rect-stroke: none;
  --icon-column-fill: #edeff2;
  --menu-item-highlight: var(--quo-grey-15);
  --menu-item-selected: var(--quo-blue-25);
  --default-border: var(--quo-grey-25);
  --placeholder-bg: #d9d9d9;
  --active-color: var(--quo-power-75);
  --drawer-border-color: var(--quo-grey-25);
  --error-message-bg: var(--quo-power-25);
  --error-message-font: var(--content-font);
  --success-message-bg: var(--quo-blue-25);
  --success-message-font: var(--content-font);
  --warning-message-bg: var(--quo-yellow);
  --warning-message-font: var(--content-font);

  /* Date Picker States */
  --datePicker-hover: var(--quo-blue-25);
  --datePicker-selected: var(--quo-blue-50);
  --datePicker-selectedStartEnd: var(--quo-blue-75);
  --datePicker-selectionMiddleHover: var(--quo-blue-75);
  --datePicker-presetHover: var(--quo-blue-75);

  /* Report */
  --spotlight-bg-color: #e4ede9;

  /* Nav */
  --icon-size: 20px;
  --svg-icon-color: var(--quo-char-75);
  --sidebar-flyout-active: var(--quo-grey-25);
  --dropdown-menu-bg: var(--mercury-bg);
  --dropdown-item-border: var(--quo-grey-15);
  --dropdown-item-hover-bg: var(--quo-grey-15);
  --notification-badge-color: var(--quo-power-75);
  --notification-unread-color: var(--quo-blue-75);

  /* Toast Notifications */
  --message-success: var(--quo-green);
  --message-warning: var(--quo-yellow);
  --message-error: var(--quo-power-75);
}

body {
  overflow-x: hidden;
}

svg {
  fill: var(--svg-icon-color);
}

.cm-editor {
  font-size: 16px;
}

.cm-editor .cm-content {
  font-family: 'Inter';
  line-height: 1.4;
}

.cm-scroller {
  overscroll-behavior: contain;
}
